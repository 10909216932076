import { Box } from "@mui/material";
import DigitalMediaKit from "../../../components/Press/DigitalMediaKit/DigitalMediaKit";
import MediaCoverage from "../../../components/Press/MediaCoverage/MediaCoverage";
import PressRelease from "../../../components/Press/PressRelease/PressRelease";

const PressAndMedia = () => {
  // on first load scroll to top
  window.scrollTo(0, 0);
  return (
    <Box sx={{ pt: { xs: 4, md: 8 } }}>
      <PressRelease />
      <MediaCoverage />
      <DigitalMediaKit />
    </Box>
  );
};

export default PressAndMedia;
