import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import navStyles from "../../styles/Navbar.module.css";
// import DE from "../../assets/icons/DE.svg";
// import ES from "../../assets/icons/ES.svg";
// import FR from "../../assets/icons/FR.svg";
// import US from "../../assets/icons/US.svg";

const Navbar = (props) => {
  const [menuItem, setMenuItem] = useState([]);
  useEffect(() => {
    fetch(`./json/shared/navbar/navbar.json`)
      .then((res) => res.json())
      .then((data) => setMenuItem(data));
  }, []);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [open, setOpen] = useState(null);
  // const [lang, setLang] = useState("en");

  const navigate = useNavigate();
  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setOpen(null);
  // };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ px: 2 }}>
      <Box sx={{ textAlign: "right", mt: 2 }}>
        <IconButton
          color="inherit"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ mr: 4, color: "#fff" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ py: { xs: 2, md: 4 }, px: 2, mt: { xs: 2, md: 4 } }}>
        <nav
          style={{
            display: "flex",
            flexDirection: "column",
            gap: isMobile ? 5 : 16,
          }}
        >
          <Button
            sx={{
              px: 0,
              py: 1,
              width: 1,
              justifyContent: "flex-start",
            }}
            onClick={() => {
              handleDrawerToggle();
              navigate("/");
            }}
            variant="text"
          >
            <img
              className={navStyles.homeIcon}
              src={"./assets/icons/home.svg"}
              alt="sivoo home"
            />
          </Button>
          {menuItem.map((menu) => (
            <NavLink
              key={menu.id}
              className={(navInfo) =>
                navInfo.isActive ? navStyles.navLinkActive : navStyles.navLink
              }
              to={menu.link}
            >
              <span onClick={handleDrawerToggle}>{menu.name}</span>
              <Box sx={{ pl: 1.5, my: 1 }}>
                {menu?.subMenus?.length > 0 && (
                  <Stack direction="column" gap={1}>
                    {menu?.subMenus?.map((subMenu) => (
                      <NavHashLink
                        scroll={(el) =>
                          setTimeout(() => {
                            el.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                          }, 100)
                        }
                        key={subMenu.id}
                        className={navStyles.mobileSubMenu}
                        to={`${subMenu.mainLink}${subMenu.anchor}`}
                      >
                        <span onClick={handleDrawerToggle}>{subMenu.name}</span>
                      </NavHashLink>
                    ))}
                  </Stack>
                )}
              </Box>
            </NavLink>
          ))}

          {/* <Link to={`/inverstor-relations`}>
                      <span className={navStyles.navLink}>
                        Investor Relations
                      </span>
                    </Link> */}

          {/* <Button
            onClick={handleOpen}
            variant="text"
            sx={{
              fontSize: 18,
              color: "#fff",
              textTransform: "uppercase",
              width: 5,
              p: 0,
              opacity: 0.8,
            }}
          >
            {lang}{" "}
            <img
              style={{ marginLeft: 10 }}
              src={
                lang === "en"
                  ? US
                  : lang === "de"
                  ? DE
                  : lang === "es"
                  ? ES
                  : lang === "fr"
                  ? FR
                  : US
              }
              alt={lang}
            />
          </Button> */}
        </nav>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box sx={{ display: "flex", boxShadow: 0 }}>
        <AppBar
          className={navStyles.navBar}
          position="fixed"
          sx={{ boxShadow: 1 }}
        >
          <Toolbar
            sx={{
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: { xs: 1, md: 4 },
              }}
            >
              <Link className={navStyles.logo} to="/">
                <img
                  src={"./assets/icons/logo.svg"}
                  alt="Sivoo"
                  width={"100%"}
                  height={isMobile ? 30 : 40}
                />
              </Link>
              <Box
                sx={{
                  display: { xs: "none", md: "block" },
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: { xs: 0.2, md: 4 },
                }}
              >
                <Box
                  component="nav"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 3,
                  }}
                >
                  <Button
                    sx={{
                      mr: -1,
                    }}
                    onClick={() => navigate("/")}
                    variant="text"
                  >
                    <img
                      className={navStyles.homeIcon}
                      src={"./assets/icons/home.svg"}
                      alt="sivoo home"
                    />
                  </Button>
                  {menuItem.map((menu) => (
                    <>
                      <NavLink
                        key={menu.id}
                        className={(navInfo) =>
                          navInfo.isActive
                            ? navStyles.navLinkActive
                            : navStyles.navLink
                        }
                        to={menu.link}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.1,
                          }}
                          variant="span"
                        >
                          {menu.name}
                          {menu.subMenus?.length > 0 && (
                            <KeyboardArrowDownIcon />
                          )}
                        </Typography>
                        {menu?.subMenus?.length > 0 && (
                          <Box className={navStyles.subMenuBox}>
                            <Box className={navStyles.subMenu}>
                              {menu?.subMenus?.map((subMenu) => (
                                <HashLink
                                  scroll={(el) =>
                                    setTimeout(() => {
                                      el.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                      });
                                    }, 100)
                                  }
                                  key={subMenu.id}
                                  className={(navInfo) =>
                                    navInfo.isActive
                                      ? navStyles.navLinkActive
                                      : navStyles.navLink
                                  }
                                  to={`${subMenu.mainLink}${subMenu.anchor}`}
                                >
                                  <span>{subMenu.name}</span>
                                </HashLink>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </NavLink>
                    </>
                  ))}

                  {/* <Link to={`/opportunity`}>
                      <span className={navStyles.navLink}>
                        Opportunity
                      </span>
                    </Link> */}

                  {/* <Button
                    onClick={handleOpen}
                    variant="text"
                    sx={{
                      fontSize: 20,
                      color: "#fff",
                      textTransform: "uppercase",
                      opacity: 0.8,
                    }}
                  >
                    {lang}{" "}
                    <img
                      style={{ marginLeft: 10 }}
                      src={
                        lang === "en"
                          ? US
                          : lang === "de"
                          ? DE
                          : lang === "es"
                          ? ES
                          : lang === "fr"
                          ? FR
                          : US
                      }
                      alt={lang}
                    />
                  </Button> */}
                </Box>
              </Box>
              <Box sx={{ display: { md: "none" } }}>
                <IconButton
                  color="inherit"
                  edge="end"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        {/*  */}
        <Box
          component="nav"
          sx={{ flexShrink: { sm: 0 } }}
          className={navStyles.sideDrawer}
        >
          <Drawer
            container={container}
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", md: "none" },
              zIndex: 99999,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { xs: "70%", md: "20%" },
                bgcolor: "#25262C",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      {/* language switcher popover */}
      {/* <Popover
        sx={{
          zIndex: 99999,
        }}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: isMobile ? "center" : "bottom",
          horizontal: isMobile ? "right" : "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isMobile ? "left" : "center",
        }}
      >
        <Stack sx={{ p: 1 }}>
          <MenuItem
            onClick={() => {
              setLang("en");
              handleClose();
            }}
          >
            EN <img style={{ marginLeft: 10 }} src={US} alt="" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setLang("fr");
              handleClose();
            }}
          >
            FR <img style={{ marginLeft: 10 }} src={FR} alt="" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setLang("de");
              handleClose();
            }}
          >
            DE <img style={{ marginLeft: 10 }} src={DE} alt="" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setLang("es");
              handleClose();
            }}
          >
            ES <img style={{ marginLeft: 10 }} src={ES} alt="" />
          </MenuItem>
        </Stack>
      </Popover> */}
    </div>
  );
};

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
