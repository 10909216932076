import { Box } from "@mui/material";
import FAQ from "../../../components/Technology/FAQ/FAQ";
import SivooCloud from "../../../components/Technology/SivooCloud/SivooCloud";
import SivooTVPlus from "../../../components/Technology/SivooTVPlus/SivooTVPlus";
import TV from "../../../components/Technology/TV/TV";
import Partners from "../../../components/shared/Partners/Partners";

const Technology = () => {
  window.scrollTo(0, 0);
  return (
    <Box sx={{ pt: { xs: 4, md: 8 } }}>
      <TV />
      <SivooCloud />
      <SivooTVPlus />
      <div id="Partners">
        <Partners />
      </div>
      <FAQ />
    </Box>
  );
};

export default Technology;
