import Hero from "../../../components/Home/Hero/Hero";

const Home = () => {
  return (
    <div>
      <Hero />
    </div>
  );
};

export default Home;
