import { Box } from "@mui/material";
import ContactForm from "../../../components/Contact/Contact";

const Contact = () => {
  window.scrollTo(0, 0);
  return (
    <Box sx={{ pt: { xs: 4, md: 8 } }}>
      <Box>
        <ContactForm />
      </Box>
    </Box>
  );
};

export default Contact;
