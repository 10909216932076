import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Button, Grid, Typography } from "@mui/material";

const PlaceList = ({ area }) => {
  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h3" gutterBottom>
        {area.name}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: 16, md: 22 },
        }}
        variant="h3"
        gutterBottom
      >
        {area.subName}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={12} xl={6}>
          {area?.places
            ?.slice(0, Math.ceil(area.places.length / 2))
            .map((place) => (
              <Box key={place.id}>
                <Button
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                  }}
                  color="info"
                  variant="text"
                  endIcon={<ArrowRightAltIcon />}
                >
                  {place.name}
                </Button>
              </Box>
            ))}
        </Grid>
        <Grid item xs={12} sm={6} lg={12} xl={6}>
          {area?.places
            ?.slice(Math.ceil(area.places.length / 2))
            .map((place) => (
              <Box key={place.id}>
                <Button
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                  }}
                  color="info"
                  variant="text"
                  endIcon={<ArrowRightAltIcon />}
                >
                  {place.name}
                </Button>
              </Box>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlaceList;
