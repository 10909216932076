import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const MediaCoverage = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetch(`./json/press/mediaCoverage.json`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <div id={data.anchor}>
      <Box
        sx={{
          py: 6,
          px: { xs: 3, md: 8 },
        }}
      >
        <Typography className="sectionTitle" variant="h3">
          {data.sectionTitle}
        </Typography>

        <Box sx={{ mt: 6 }}>
          <Swiper
            pagination={false}
            loop={true}
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            speed={1500}
            grabCursor={true}
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              960: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
            spaceBetween={50}
          >
            {data?.mediaCoverages?.map((item) => (
              <SwiperSlide key={item.id}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <img
                      style={{
                        width: "90%",
                        display: "inline-block",
                      }}
                      src={item.image}
                      alt={item.title}
                    />
                  </a>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </div>
  );
};

export default MediaCoverage;
