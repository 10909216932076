import { Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

const Error = () => {
  const error = useRouteError();
  const { status, statusText } = error;
  const navigate = useNavigate();
  console.error(error);
  useEffect(() => {
    if (status === 404) { 
      navigate(`/notfound`);
    }
  })

  return (
    <div>
      <Container
        sx={{
          py: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: 48,
              md: 72,
            },
            letterSpacing: {
              xs: 0,
              md: 8,
            },
          }}
          color="secondary"
          variant="h1"
          align="center"
          gutterBottom
        >
          {status}
        </Typography>
        <Typography color="info.main" variant="h3" align="center" gutterBottom>
          {statusText}
        </Typography>
      </Container>
    </div>
  );
};

export default Error;
