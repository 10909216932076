import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const PressSlider = ({ events }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    initialSlide: currentSlide,
    draggable: true,
  };

  const slideIndicatorStyle = {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    top: "60px",
    left: isMobile ? "22px" : "62px",
    width: "100%",
    height: "30px",
    backgroundColor: "transparent",
    zIndex: 999,
  };

  const slideNumberStyle = {
    display: "inline-block",
    width: isMobile ? "30px" : "60px",
    height: "4px",
    margin: "0px 4px",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    opacity: "0.4",
    transition: "opacity 0.3s",
  };

  const activeSlideNumberStyle = {
    ...slideNumberStyle,
    backgroundColor: "#ff6600",
    opacity: "1",
  };

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box style={slideIndicatorStyle}>
        {events.map((event, i) => (
          <button
            key={event.id}
            onClick={() => handleIndicatorClick(i)}
            style={{
              border: "none",
              background: "transparent",
              padding: 0,
              cursor: "pointer",
              zIndex: 999,
            }}
          >
            <Box
              style={
                currentSlide === i ? activeSlideNumberStyle : slideNumberStyle
              }
            />
          </button>
        ))}
      </Box>
      <Slider ref={sliderRef} draggable={true} {...settings}>
        {events.map((event) => (
          <>
            <Box
              sx={{
                backgroundImage: event.bgImage
                  ? `url(${event.bgImage})`
                  : `url(${"./assets/images/press-bg.png"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: {
                  xs: "100px 20px 0px 20px",
                  md: "100px 20px 40px 20px",
                },
                cursor: "grab",
                px: { xs: 3, md: 8 },
              }}
              key={event.id}
            >
              <Box>
                <Box>
                  <Typography
                    sx={{
                      mb: 2,
                    }}
                    variant="h3"
                  >
                    {event.date}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",

                    mt: { xs: 4, md: 12 },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: 20, sm: 24, lg: 28 },
                      }}
                      variant="h3"
                    >
                      {event.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: 16, sm: 20, lg: 24 },
                      }}
                      variant="subtitle1"
                    >
                      {event.subtitle}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ mb: 2, fontSize: { xs: 16, sm: 20, lg: 24 } }}
                    >
                      {event.location}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "none", lg: "block" },
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: "36px",
                        mb: 3,
                        whiteSpace: "pre-line",
                      }}
                      variant="body1"
                    >
                      {event.description}
                    </Typography>
                    <Button variant="text" color="secondary">
                      <a
                        style={{
                          color: "#ff6600",
                          fontSize: isMobile ? 16 : 24,
                        }}
                        href={event.pressLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Read full press release
                      </a>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", lg: "none" },
                p: 3,
              }}
            >
              <Typography
                sx={{
                  lineHeight: "20px",
                  mb: 3,
                  whiteSpace: "pre-line",
                }}
                variant="body1"
              >
                {event.description}
              </Typography>
              <Button variant="text" color="secondary">
                <a
                  style={{
                    color: "#ff6600",
                    fontSize: isMobile ? 16 : 24,
                  }}
                  href={event.pressLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Read full press release
                </a>
              </Button>
            </Box>
          </>
        ))}
      </Slider>
    </Box>
  );
};

export default PressSlider;
