import CheckIcon from "@mui/icons-material/Check";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import Form from "./Form";

const Contact = () => {
  const [success, setSuccess] = useState(false);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 4, md: 12 }}
        sx={{ p: 0 }}
      >
        <Grid
          sx={{
            px: { xs: 4, md: 8 },
          }}
          item
          xs={2}
          sm={4}
          md={12}
        >
          <Box sx={{ py: 6, pl: { xs: 3, md: 8 } }}>
            {success ? (
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography variant="h1" gutterBottom>
                  Thanks for your interest
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Your message has been received and we will get back to you as
                  soon as possible.
                </Typography>
                <Box sx={{ my: 4, textAlign: "center" }}>
                  <CheckIcon
                    sx={{ fontSize: { xs: 60, md: 100 }, color: "#18ED60" }}
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography className="sectionTitle" variant="h3" gutterBottom>
                  Contact Us
                </Typography>

                <Typography sx={{ my: 6 }} variant="body1" gutterBottom>
                  For press inquiries and other matters related to the media,
                  investor inquiries, product sales or general feedback send us a message and
                  we’ll get back to you soon.
                </Typography>
                <Box sx={{ my: 4 }}>
                  <Form setSuccess={setSuccess} />
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {/* <Grid sx={{}} item xs={2} sm={4} md={5}>
          <Box
            sx={{
              py: 6,
              width: "100%",
              height: "100%",
            }}
          >
            
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Contact;
