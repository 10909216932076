import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";

const SivooTVPlus = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [data, setData] = useState({});
  useEffect(() => {
    fetch("./json/technology/sivooPlus.json")
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);

  // on click open the link in a new tab
  const handleClick = () => {
    window.open("https://sivootv.com", "_blank");
  };

  return (
    <div id={data?.anchor}>
      <Box sx={{ py: 6 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3, lg: 6 }}
            columns={{ xs: 2, sm: 4, md: 6, lg: 12 }}
          >
            <Grid item xs={2} sm={4} md={6} lg={6}>
              <Box>
                <img src={data?.image} alt={data.sectionTitle} />
              </Box>
            </Grid>
            <Grid item xs={2} sm={4} md={6} lg={6}>
              <Box sx={{ px: { xs: 3, md: 4 } }}>
                <Typography className="sectionTitle" variant="h3">
                  {data?.sectionTitle}
                </Typography>

                <Typography
                  sx={{ mt: 6, whiteSpace: "pre-line" }}
                  variant="body1"
                >
                  {data?.description}
                </Typography>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Button
                    onClick={handleClick}
                    variant="contained"
                    color="secondary"
                    sx={{
                      color: "#fff",
                      borderRadius: 3,
                      mt: 4,
                    }}
                  >
                    Check SivooTV.com for updates
                  </Button>
                </Box>
                <Box sx={{ pl: 4, my: 4 }} alignContent={"center"}>
                  <ul
                    style={{
                      listStyleType: "square",
                    }}
                  >
                    {data?.features?.length > 0 &&
                      data?.features?.map((feature) => (
                        <li
                          key={feature.id}
                          style={{
                            fontSize: isMobile ? 16 : 20,
                          }}
                        >
                          {feature.text}
                        </li>
                      ))}
                  </ul>
                </Box>
                <Stack
                  sx={{ my: 4, flexWrap: "wrap" }}
                  direction="row"
                  justifyContent="center"
                  gap={1}
                >
                  {data?.buttons?.map((button) => (
                    <Box key={button.id}>
                      <a
                        href={button.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img className="storeLogo" src={button.icon} alt="" />
                      </a>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default SivooTVPlus;
