import { Box, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Opportunity = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetch(`./json/corporate/opportunity.json`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <div id={data.anchor}>
      <Box sx={{ py: 6 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3, lg: 6 }}
            columns={{ xs: 2, sm: 4, md: 6, lg: 12 }}
          >
            <Grid item xs={2} sm={4} md={6} lg={6}>
              <Box sx={{ px: { xs: 3, md: 4 } }}>
                <Typography className="sectionTitle" variant="h3">
                  {data.sectionTitle}
                </Typography>

                <Typography
                  sx={{ mt: 6, whiteSpace: "pre-line" }}
                  variant="body1"
                >
                  {data.description}
                  <Link sx={{ cursor: "pointer", color: "white" }} to="mailto://{data.email}">{data.email}</Link>.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sm={4} md={6} lg={6}>
              <Box>
                <img src={data.image} alt={data.sectionTitle} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Opportunity;
