import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloudNetwork from "../CloudNetwork/CloudNetwork";

const SivooCloud = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetch(`./json/technology/sivooCloud.json`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <div id={data.anchor}>
      <Box sx={{ py: 6, px: { xs: 3, md: 6 } }}>
        <Typography className="sectionTitle" variant="h3">
          {data.sectionTitle}
        </Typography>

        <Typography
          sx={{ mb: 2, mt: 6, whiteSpace: "pre-line" }}
          variant="body1"
        >
          {data.description}
        </Typography>
        <Box sx={{ flexGrow: 1, my: 2 }}>
          {data?.places?.length > 0 && <CloudNetwork data={data} />}
        </Box>

        <Typography variant="span">
          * represents active network nodes.
          <br />
          <i>Click regions to list proposed city nodes.</i>
        </Typography>
      </Box>
    </div>
  );
};

export default SivooCloud;
