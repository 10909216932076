import { Box, Grid } from "@mui/material";
import { useState } from "react";
import styles from "../../../styles/Home.module.css";
import PlaceList from "./PlaceList";

const CloudNetwork = ({ data }) => {
  const [selectedArea, setSelectedArea] = useState("americasPlaces");
  return (
    <div>
      <Box sx={{ flexGrow: 1, mt: 4 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 4, md: 6, lg: 12 }}
          flexDirection={{ xs: "column-reverse", lg: "row" }}
        >
          <Grid item xs={2} sm={4} md={6} lg={4}>
            <Box>
              {selectedArea === "americasPlaces" ? (
                <PlaceList area={data?.places[0]?.americasPlaces} />
              ) : selectedArea === "euporePlaces" ? (
                <PlaceList area={data?.places[1]?.euporePlaces} />
              ) : selectedArea === "asiaPlaces" ? (
                <PlaceList area={data?.places[2]?.asiaPlaces} />
              ) : (
                <PlaceList area={data?.places[0]?.americasPlaces} />
              )}
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={6} lg={8}>
            <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
              <img
                className={styles.map}
                src={data.image}
                alt={data.sectionTitle}
              />
              <Box
                onClick={() => setSelectedArea("americasPlaces")}
                className={`${styles.americasMap} ${
                  selectedArea === "americasPlaces" && styles.selectedArea
                }`}
              />
              <Box
                onClick={() => setSelectedArea("euporePlaces")}
                className={`
                  ${styles.europeMap} ${
                  selectedArea === "euporePlaces" && styles.selectedArea
                }
                  `}
              />
              <Box
                onClick={() => setSelectedArea("asiaPlaces")}
                className={`${styles.asiaMap} ${
                  selectedArea === "asiaPlaces" && styles.selectedArea
                }`}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CloudNetwork;
