import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "../../../styles/Press.module.css";
import PressSlider from "./PressSlider";

const PressRelease = () => {
  const [releaseEvents, setReleaseEvents] = useState([]);
  const [value, setValue] = useState("");

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // load data from ./pressRelease.json
    fetch("./json/press/pressRelease.json")
      .then((res) => res.json())
      .then((data) => setReleaseEvents(data));
  }, []);

  useEffect(() => {
    if (releaseEvents.length > 0) {
      // setValue(releaseEvents[0].year);
      // set the value to the latest year
      setValue(releaseEvents[releaseEvents.length - 1].year);
    }
  }, [releaseEvents]);

  if (releaseEvents.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <Grid
        container
        spacing={{ xs: 0, lg: 2 }}
        columns={{ xs: 2, sm: 4, lg: 12 }}
        flexDirection={{ xs: "column-reverse", lg: "row" }}
      >
        <Grid item xs={2} sm={4} lg={12}>
          <Box
            sx={{
              position: "relative",
            }}
            className={styles.pressRelease}
          >
            <div id="Press-Releases">
              {releaseEvents.length > 0 ? (
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 0,
                    }}
                  >
                    <TabList
                      textColor="secondary"
                      indicatorColor="none"
                      variant="scrollable"
                      scrollButtons="auto"
                      onChange={handleChange}
                      sx={{
                        position: "absolute",
                        top: "20px",
                        left: { xs: "20px", md: "60px" },
                        width: "100%",

                        zIndex: 9999,
                      }}
                    >
                      {releaseEvents.map((event) => (
                        <Tab
                          sx={{
                            fontSize: { xs: 18, md: 24 },
                            p: { xs: 0, md: 1 },
                            minWidth: { xs: "50px", md: "50px" },
                          }}
                          key={event.id}
                          value={event.year}
                          label={event.year}
                        ></Tab>
                      ))}
                    </TabList>
                    {releaseEvents.map((event) => (
                      <Box key={event.id}>
                        <TabPanel
                          sx={{
                            p: 0,
                          }}
                          value={event.year}
                        >
                          <PressSlider events={event.events} />
                        </TabPanel>
                      </Box>
                    ))}
                  </Box>
                </TabContext>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <Typography variant="h3">
                    No data available at the moment
                  </Typography>
                </Box>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PressRelease;
