import { createTheme, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import routes from "./routes/routes";

// customize theme
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#046FED",
    },
    secondary: {
      main: "#ff6600",
    },
    info: {
      main: "#fff",
    },
    background: {
      default: "#25262C",
      paper: "#25262C",
    },
    text: {
      primary: "#fff",
    },
  },
  typography: {
    fontFamily: "Signika",
  },
});
theme.typography.h1 = {
  fontSize: "24px",
  [theme.breakpoints.up("md")]: {
    fontSize: "40px",
  },
};

theme.typography.h3 = {
  fontSize: "18px",
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
};
theme.typography.h6 = {
  fontSize: "16px",
  [theme.breakpoints.up("md")]: {
    fontSize: "18px",
  },
};

theme.typography.body1 = {
  fontSize: "20px",
  fontWeight: 400,
  [theme.breakpoints.up("md")]: {
    fontSize: "24px",
  },
};

theme.components.MuiButton = {
  styleOverrides: {
    root: {
      textTransform: "none",
      borderRadius: "2px",
      fontSize: "18px",
      fontWeight: 400,
      [theme.breakpoints.up("md")]: {
        fontSize: "24px",
      },
    },
  },
};

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <RouterProvider router={routes} />
      </ThemeProvider>
    </div>
  );
};

export default App;
