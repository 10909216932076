import { Container, Typography } from "@mui/material";

const Error404 = () => {
  return (
    <div>
      <Container
        sx={{
          pt: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            mb: 3,
            fontSize: {
              xs: 48,
              md: 72,
            },
            letterSpacing: {
              xs: 0,
              md: 8,
            },
          }}
          color="secondary"
          variant="h1"
          align="center"
        >
          Oops! Not here...
        </Typography>
        <Typography color="info.main" variant="h3" align="center" gutterBottom>
          The file or directory you're looking for does not exist.
        </Typography>
      </Container>
    </div>
  );
};

export default Error404;
