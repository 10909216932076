import { Box } from "@mui/material";
import OOVISPlatform from "../../../components/Services/OOVISPlatform/OOVISPlatform";
import VideoStreaming from "../../../components/Services/VideoStreaming/VideoStreaming";

const Services = () => {
  window.scrollTo(0, 0);
  return (
    <Box sx={{ pt: { xs: 4, md: 8 } }}>
      <VideoStreaming />
      <OOVISPlatform />
    </Box>
  );
};

export default Services;
