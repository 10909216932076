import { Box } from "@mui/material";
import Error404 from "../../../components/Error/Error404";

const NotFound = () => {
  window.scrollTo(0, 0);
  return (
    <Box sx={{ pt: { xs: 4, md: 8 } }}>
      <Box>
        <Error404 />
      </Box>
    </Box>
  );
};

export default NotFound;
