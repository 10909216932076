const menuItem = [
  {
    id: 1,
    name: "Technology",
    link: "/technology",
    subMenus: [
      {
        id: 1,
        name: "SivooTV",
        anchor: "#SivooTV",
        mainLink: "/technology",
      },
      {
        id: 2,
        name: "SivooCloud",
        anchor: "#SivooCloud",
        mainLink: "/technology",
      },
      {
        id: 3,
        name: "SivooTV+",
        anchor: "#SivooTV+",
        mainLink: "/technology",
      },
      {
        id: 4,
        name: "Partners",
        anchor: "#Partners",
        mainLink: "/technology",
      },
      {
        id: 5,
        name: "FAQs",
        anchor: "#FAQs",
        mainLink: "/technology",
      },
    ],
  },
  {
    id: 2,
    name: "Services",
    link: "/services",
    subMenus: [
      {
        id: 1,
        name: "Video Streaming",
        anchor: "#Video-Streaming",
        mainLink: "/services",
      },
      {
        id: 2,
        name: "OOVIS Platform",
        anchor: "#OOVIS-Platform",
        mainLink: "/services",
      },
      {
        id: 3,
        name: "Partners",
        anchor: "#Partners",
        mainLink: "/services",
      },
      {
        id: 4,
        name: "Pricing",
        anchor: "#Pricing",
        mainLink: "/services",
      },
    ],
  },

  {
    id: 3,
    name: "Press & Media",
    link: "/press-media",
    subMenus: [
      {
        id: 1,
        name: "Press Releases",
        anchor: "#Press-Releases",
        mainLink: "/press-media",
      },
      {
        id: 2,
        name: "Media Coverage",
        anchor: "#Media-Coverage",
        mainLink: "/press-media",
      },
      {
        id: 3,
        name: "Digital Media Kit",
        anchor: "#Digital-Media-Kit",
        mainLink: "/press-media",
      },
    ],
  },
  {
    id: 4,
    name: "Corporate",
    link: "/corporate",
    subMenus: [
      {
        id: 1,
        name: "About",
        anchor: "#About",
        mainLink: "/corporate",
      },
      {
        id: 2,
        name: "Opportunity",
        anchor: "#Opportunity",
        mainLink: "/corporate",
      },
    ],
  },
  {
    id: 5,
    name: "Contact",
    link: "/contact",
  },
];

export default menuItem;
