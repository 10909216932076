import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  useEffect(() => {
    fetch(`./json/shared/partners/partners.json`)
      .then((res) => res.json())
      .then((data) => setPartners(data));
  }, []);
  return (
    <Box className="partners">
      <Box sx={{ py: 6, px: { xs: 4, md: 8 } }}>
        <Typography className="sectionTitle" variant="h3">
          OUR PARTNERS
        </Typography>
        <Box sx={{ mt: 6 }}>
          <Swiper
            pagination={false}
            loop={true}
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            speed={1500}
            grabCursor={true}
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 3,
              },
              960: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
            spaceBetween={50}
          >
            {partners.map((partner) => (
              <SwiperSlide key={partner.id}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "90%",
                      display: "inline-block",
                    }}
                    src={partner.logo}
                    alt={partner.name}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};

export default Partners;
