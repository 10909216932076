import { Box, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import menuItem from "../../utils/menuItem";

const Footer = () => {
  return (
    <div>
      <Box sx={{ py: 6, px: { xs: 3, md: 8 } }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 2, sm: 4, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={2}>
              <Box>
                <Link to="/">
                  <img src={"./assets/icons/logo.svg"} alt="sivoo" />
                </Link>
              </Box>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Stack direction="row" gap={4}>
                <Stack direction="column" gap={1}>
                  {menuItem.slice(0, 4).map((menu) => (
                    <Link key={menu.id} to={menu.link}>
                      {menu.name}
                    </Link>
                  ))}
                </Stack>

                <Stack direction="column" gap={1}>
                  <Link to="/terms">Terms of Service</Link>
                  <Link to="/privacy">Privacy Policy</Link>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: { xs: "flex-start", md: "flex-end" },
                  flexDirection: { xs: "column-reverse", md: "column" },
                  gap: 1,
                }}
              >
                <>
                  <Link to="/contact">
                    <Typography
                      sx={{
                        fontSize: 16,
                      }}
                      variant="h6"
                    >
                      Contact Us
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      width: { xs: 1, md: 2 / 3 },
                      textAlign: { xs: "left", md: "right" },
                      fontSize: 16,
                    }}
                    variant="body2"
                  >
                    1900 John F. Kennedy Blvd., Suite 1323 <br />
                    Philadelphia, PA 19103 <br />
                    United States <br/>
                    (844) GO-SIVOO
                  </Typography>
                  <Typography
                    sx={{
                      mt: -1,
                      fontStyle: "italic",
                      fontSize: 16
                    }}
                    variant="body2"
                  >
                    [467-4866]
                  </Typography>
                </>
                <Box sx={{ mt: 4 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                  >
                    <a
                      href="https://www.linkedin.com/company/sivooinc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={"./assets/icons/linkedin.svg"}
                        alt="sivoo linkedin"
                      />
                    </a>
                    <a
                      href="https://twitter.com/sivooinc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={"./assets/icons/twitter.svg"}
                        alt="sivoo twitter"
                      />
                    </a>
                    <a
                      href="https://www.facebook.com/sivooinc/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={"./assets/icons/facebook.svg"}
                        alt="sivoo facebook"
                      />
                    </a>

                    <a
                      href="https://www.instagram.com/sivooinc/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={"./assets/icons/instagram.svg"}
                        alt="sivoo instagram"
                      />
                    </a>
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
