import React from "react";
import About from "../../../components/Corporate/About/About";
import Opportunity from "../../../components/Corporate/Opportunity/Opportunity";
import { Box } from "@mui/material";

const Corporate = () => {
  window.scrollTo(0, 0);
  return (
    <Box sx={{ pt: { xs: 4, md: 8 } }}>
      <About />
      <Opportunity />
    </Box>
  );
};

export default Corporate;
