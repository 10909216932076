import { Box, Container, Stack, Typography } from "@mui/material";
import "../../../styles/Privacy-Terms.css";

const Terms = () => {
  // on first load scroll to top
  window.scrollTo(0, 0);
  return (
    <div>
      <Container sx={{ pt: 8, pb: 4 }}>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography sx={{ my: 2 }} variant="h1">
              Terms of Service
            </Typography>
            <Typography variant="body1">
              Last updated: February 9, 2023
            </Typography>
          </Box>
          <a
            href="https://www.sivoo.com/assets/pdf/Sivoo_TermsOfService.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"./assets/icons/pdfIcon.png"} alt="" />
          </a>
        </Stack>
      </Container>

      {/* terms  */}
      <Container sx={{ py: 6 }}>
        <Typography
          sx={{ mb: 2, fontSize: { xs: 18, md: 22, fontWeight: "300" } }}
          variant="body1"
        >
          This <b>SIVOO Inc. Terms of Service Agreement</b> (the{" "}
          <b>“Agreement”</b> or <b>“Terms of Service”</b>) is made between
          SIVOO, Inc. (<b>“SIVOO Inc.,” “we,” “us,”</b> or <b>“our”</b>) and
          you, our customer (<b>“you”</b> or <b>“your”</b>). This Agreement
          governs your use of SIVOO Inc. owned-and-operated websites,
          applications, and embeddable video players (collectively, the{" "}
          <b>“Services”</b>). This includes our SIVOO, SIVOOTV, SIVOO OTT, and
          Livestream services.
        </Typography>
        <Typography
          sx={{ mb: 2, fontSize: { xs: 18, md: 22, fontWeight: "300" } }}
          variant="body1"
        >
          <p>
            This T&C is an electronic record in terms of the Information
            Technology Act, 2000 (as amended / re-enacted) and rules thereunder,
            and is published in accordance with the provisions of Rule 3 (1) of
            the Information Technology (Intermediaries Guidelines and Digital
            Media Ethics code) Rules, 2021, which mandates publishing of rules
            and regulations, privacy policy and terms of use for access or usage
            of the App. This electronic record is generated by a computer system
            and does not require any physical or digital signature.
          </p>
          <p>
            Notice to Users of the Republic of India (Bhārat Gaṇarājya) Under
            G.S.R. 139(E): Intermediary Guidelines and Digital Media Ethics
            Code, users located in the Republic of India are entitled to the
            following Grievance Redressal Mechanism. If user's issue is not
            resolved even after contacting various complaint resolution
            channels, he/she can reach out to our grievance officer by
            contacting our grievance officer at the below email ID, address, and
            number. In your mail you must describe the issue in detail and
            provide any supporting document including screenshots that would
            assist with the resolution of your concern.
          </p>
          <p>
            The following apps belong to Sivoo Inc.:
            <br />
            HERITAGE TOURISM, HERITAGE+, S+, ESCAPE TV, BANG BANG TV, HEARTFELT
            TV, LOL TV, OUTER VISION TV, HORROR TV, THE CHUKKER CHANNEL TV,
            DANGER VISION TV, ENCORE+, KIDDO+, MERCURY+, OSC+, Xtrem Sports,
            Travel TV, Mollywood TV, Lollywood TV, Kollywood TV, Pollywood TV,
            English Club TV, Tollywood TV, Bollywood TV, Clubbing TV, Kids Zone.
          </p>
        </Typography>
        <Box sx={{ px: 4 }}>
          <ol class="privacy">
            <li>
              Acceptance
              <p>
                By creating an account, viewing videos, making a purchase,
                downloading our software, or otherwise visiting or using our
                Services, you accept this Agreement and consent to contract with
                us electronically.
              </p>
              <p>
                If you are an entity other than a natural person, the person who
                registers the account or otherwise uses our Services must have
                the authority to bind the entity. In this context, “you” means
                both the entity and each person who is authorized to access the
                account.
              </p>
              <p>
                We may update this Agreement by posting a revised version on our
                website and our apps. By continuing to use our Services, you
                accept any revised Agreement.
              </p>
              <p>
                Please review our Privacy Policy to learn about the information
                we collect from you, how we use it, and with whom we share it.
              </p>
            </li>
            <li>
              Our Services
              <p>
                <b>Service License:</b> Subject to the terms hereof, we grant
                you access to our Services. This includes the right to:
                <blockquote>
                  <div class="ul">
                    Stream videos that you have the right to view;
                  </div>
                  <div class="ul">
                    Upload, store, and/or live stream videos, subject to your
                    plan;
                  </div>
                  <div class="ul">
                    Embed our embeddable video player on third-party websites;
                    and
                  </div>
                  <div class="ul">
                    Use all related functionality that we may provide.
                  </div>
                </blockquote>
              </p>
              <p>
                <b>Features:</b> The features available to you will depend on
                your plan. We may change features from time to time. If you have
                a paid account, we commit to providing the core video hosting
                and streaming features of your plan (including the bandwidth and
                storage capabilities stated at the time of purchase) during your
                current service period.
              </p>
              <p>
                <b>Unlimited Bandwidth Fair Use Policy:</b> Generally, we do not
                limit or impose additional fees for bandwidth consumption on
                Self-Serve accounts (i.e. the data used in order to deliver your
                videos to viewers). However, this policy is subject to fair use:
                If your aggregate bandwidth usage (across all accounts you
                control) is higher than 99% of Self-Serve users on our platform
                in any calendar month, we may, in our discretion, charge fees
                for excessive usage, require you to upgrade to a more suitable
                plan, or terminate your account(s) upon advance written notice.
              </p>
              <p>
                <b>Video Creation Tools:</b> We may allow you to use SIVOO Inc.
                Create, our video creation tools. Your use of SIVOO Inc. Create
                is governed by our SIVOO Inc. Create Addendum.
              </p>
              <p>
                <b>Transactions:</b> We may offer digital goods for sale.
                Purchases of SIVOO Inc. On Demand videos are governed by our
                SIVOO Inc. On Demand Viewer Agreement. Purchases of SIVOO Inc.
                Stock footage and licenses are governed by our SIVOO Inc. Stock
                License Agreement.
              </p>
              <p>
                <b>Monetization:</b> We may allow you to sell digital goods or
                earn money through advertising. Your sales of videos are
                governed by our Seller Addendum.
              </p>
              <p>
                <b>Downloadable Software:</b> We may offer applications for
                devices (<b>“Apps”</b>) directly or through third-party stores.
                Subject to your compliance with these Terms of Service, SIVOO
                Inc. grants you a limited, non-exclusive, non-transferable,
                revocable license to download and use the Apps. We may update
                Apps from time to time to add new features and/or correct bugs.
                You shall ensure that you are using the most recent version of
                the App that is compatible with your device. We cannot guarantee
                that you will be able to use the most recent version of the App
                on your device.
              </p>
              <p>
                <b>Developer Tools:</b> We may offer application programming
                interfaces (<b>“APIs”</b>) that allow developers to build
                applications connected to our Services. Our APIs and related
                documentation are governed by our API License Addendum.
              </p>
              <p>
                <b>Enterprise Services:</b> We may offer Services pursuant to an
                individually-negotiated agreement. Enterprise services are
                governed by our Enterprise Terms.
              </p>
              <p>
                <b>Third Parties:</b> We may provide links to and integrations
                with websites or services operated by others. Your use of each
                such website or service is subject to its terms of service and
                our Third-Party Service Addendum.
              </p>
            </li>
            <li>
              Accounts
              <p>
                <b>Registration:</b> You may create an account to use certain
                features we offer (e.g., uploading or live streaming videos). To
                do so, you must provide an email address. By creating an
                account, you agree to receive notices from SIVOO Inc. at this
                email address.
              </p>
              <p>
                <b>Organizational Accounts:</b> Corporate, governmental, and
                other organizational users must publicly display the legal name
                of their entity on their public account profile. If you are a
                government entity in the U.S., our Government Entity Addendum
                applies.
              </p>
              <p>
                <b>Age Requirements:</b> You must be at least 16 years old or
                the applicable age of majority in your jurisdiction, whichever
                is greater, to create an account or otherwise use our Services.
                If you wish to use the Services for a commercial purpose, you
                must be at least 18 years old. Individuals under the applicable
                age may use our Services only through a parent or legal
                guardian’s account and with their involvement. Please have that
                person read this Agreement with you and consent to it before
                proceeding.
              </p>
              <p>
                <b>Parents and Guardians:</b> By granting your child permission
                to use the Services through your account, you agree and
                understand that you are responsible for monitoring and
                supervising your child's usage. If you believe your child is
                using your account and does not have your permission, please
                contact us immediately so that we can disable access.
              </p>
              <p>
                <b>Team Members:</b> Certain subscription plans may allow you to
                grant other registered users (<b>“team members”</b>) access to
                the account. Both you and each team member is deemed a party to
                this Agreement. You are responsible for the actions of your team
                members and must monitor their access and usage.
              </p>
              <p>
                <b>Account Security:</b> You are responsible for all activity
                that occurs under your account, including unauthorized activity.
                You must safeguard the confidentiality of your account
                credentials. If you are using a computer that others have access
                to, you must log out of your account after each session. If you
                become aware of unauthorized access to your account, you must
                change your password and notify us immediately.
              </p>
            </li>
            <li>
              Subscription Plans
              <p>
                <b>Plan Types:</b> We offer free (Basic) memberships and paid
                subscriptions as well as allow you to upload and share video
                content. You may purchase a <b>“Self-Serve”</b> plan (you sign
                up and pay online) or an <b>“Enterprise”</b> plan (you work with
                a sales representative and execute an individualized agreement).
                Advertised prices and features may change.
              </p>
              <p>
                <b>Basic (Free) Accounts:</b> Basic (free) members may not
                submit videos that they did not create or play a material part
                in creating.
              </p>
              <p>
                <b>Fees:</b> You must pay all fees (plus any taxes) during your
                subscription period and any renewal periods. Our fees may
                include a fixed monthly or annual fee plus variable fees for
                transactions or usage.
              </p>
              <p>
                <b>Free Trials and Discounts:</b> We may offer free-trial or
                discounted subscriptions. When a free-trial period ends, your
                paid subscription begins (unless you have cancelled) and you
                must pay the full monthly or annual fee. If we provide a
                discount for the first subscription period, you must pay the
                discounted fee; in any renewal, you must pay the full fee.
              </p>
              <p>
                <b>Refund Policy:</b> Subject to the terms hereof, Self-Serve
                subscribers who purchase plans directly from SIVOO Inc. may
                cancel and receive a full refund of their initial purchase
                within thirty (30) days after purchasing an annual plan and five
                (5) days after purchasing a monthly plan. Our refund policy does
                not apply to:
                <blockquote>
                  <div class="ul">In-app purchases;</div>
                  <div class="ul">
                    Fees charged immediately after a free-trial period ends;
                  </div>
                  <div class="ul">
                    Attempted abuse of the refund policy (i.e., by subscribing
                    and requesting refunds repeatedly);
                  </div>
                  <div class="ul">
                    Subscription renewals or migrations to other plans;
                  </div>
                  <div class="ul">
                    Fees other than annual or monthly subscription fees;
                  </div>
                  <div class="ul">
                    Requests made after the specified periods;
                  </div>
                  <div class="ul">
                    Customers who have breached this Agreement or whose accounts
                    were terminated in accordance with our Copyright Policy;
                  </div>
                  <div class="ul">
                    Customers who joined using a promotion that expressly
                    disclaimed our refund policy;
                  </div>
                  <div class="ul">
                    Customers who have initiated a chargeback dispute; or
                  </div>
                  <div class="ul">Enterprise plan customers.</div>
                </blockquote>
              </p>
              <p>
                <b>
                  Automatic Renewal: To the extent permitted by applicable law,
                  subscriptions automatically renew at the end of each
                  subscription period unless cancelled beforehand. Monthly plans
                  renew for 30-day periods.
                </b>{" "}
                Annual plans renew for one-year periods. You must pay the annual
                or monthly fee (plus any taxes) when each renewal period starts.
                Unused storage, bandwidth, and other usage limits do not roll
                over.
              </p>
              <p>
                <b>
                  How to Decline Renewal: Self-Serve subscribers may opt out of
                  automatic renewal by changing their account settings.
                  Enterprise plan customers may opt out according to the
                  Enterprise Terms.
                </b>{" "}
                Any opt-out or notice of non-renewal will not affect the current
                subscription period. SIVOO Inc. may decline renewals.
              </p>
              <p>
                <b>Lapse Policy:</b> When a subscription ends, the account will,
                at SIVOO Inc.’s option, revert to Basic (free) account status or
                will be deleted. Any content in the account may be deleted to
                comply with the limitations of the new account status. You are
                responsible for archiving your content. SIVOO Inc. shall not be
                responsible for the loss of any content. We may publish
                additional guidelines regarding the treatment of lapsed
                subscriptions. These guidelines describe current practices only
                and shall not require SIVOO Inc. to provide any level of
                post-subscription account status.
              </p>
              <p>
                <b>In-App Purchase:</b> We may allow you to purchase
                subscriptions within Apps. When you make such “in-app”
                purchases, you will be billed by the app platform, not us.{" "}
                <b>
                  To turn off automatic renewal for subscriptions, access your
                  platform’s account settings (not SIVOO Inc.’s).
                </b>{" "}
                Our refund policy does not apply to in-app purchases. Any
                billing inquiries should be directed to the app platform.
              </p>
              <p>
                <b>Resale:</b> You may not sell, resell, rent, lease, or
                distribute any plan or any other aspect of our Services to any
                third party unless authorized by us in writing. We may suspend
                or terminate accounts sold via authorized resellers for
                non-payment to the reseller or any violation of the restrictions
                set forth in this Agreement.
              </p>
            </li>
            <li>
              Acceptable Use Policy
              <p>
                We may allow you to upload, live stream, submit, or publish
                (collectively, to <b>"submit"</b>) content such as videos,
                recordings, images, and text (collectively, <b>"content"</b>).
                You must ensure that your content, and your conduct, complies
                with the Acceptable Use Policy set forth in this{" "}
                <b>Section 5</b>. SIVOO Inc. may (but is not obligated to)
                monitor your account, content, and conduct, regardless of your
                privacy settings. SIVOO Inc. may remove or limit access or
                availability to any content or account that it considers in good
                faith to violate this Acceptable Use Policy.
                <ul class="privacy">
                  <li>
                    Copyright Policy
                    <p>
                      You may only upload content that you have the right to
                      upload and share. Copyright owners may send SIVOO Inc. a
                      takedown notice as stated in our Copyright Policy if they
                      believe SIVOO Inc. is hosting infringing materials. We
                      will, in appropriate circumstances, terminate the accounts
                      of persons who repeatedly infringe.
                    </p>
                  </li>
                  <li>
                    Content Restrictions
                    <p>
                      You may not submit any content that:
                      <blockquote>
                        <div class="ul">
                          Infringes any third party’s copyrights or other rights
                          (e.g., trademark, privacy rights, etc.);
                        </div>
                        <div class="ul">
                          Is sexually explicit or promotes a sexual service;
                        </div>
                        <div class="ul">Is defamatory;</div>
                        <div class="ul">Is harassing or abusive;</div>
                        <div class="ul">
                          Contains hateful or discriminatory speech;
                        </div>
                        <div class="ul">
                          Promotes or supports terror or hate groups;
                        </div>
                        <div class="ul">
                          Contains instructions on how to assemble
                          explosive/incendiary devices or homemade/improvised
                          firearms;
                        </div>
                        <div class="ul">Exploits or endangers minors;</div>
                        <div class="ul">
                          Depicts or encourages self-harm or suicide;
                        </div>
                        <div class="ul">
                          Depicts (1) unlawful real-world acts of extreme
                          violence, (2) vivid, realistic, or particularly
                          graphic acts of violence and brutality, (3) sexualized
                          violence, including rape, torture, abuse, and
                          humiliation, or (4) animal cruelty or extreme violence
                          towards animals;
                        </div>
                        <div class="ul">
                          Promotes fraudulent or dubious money-making schemes,
                          proposes an unlawful transaction, or uses deceptive
                          marketing practices;
                        </div>
                        <div class="ul">
                          Contains false or misleading claims about (1)
                          vaccination safety, or (2) health-related information
                          that has a serious potential to cause public harm;
                        </div>
                        <div class="ul">
                          Contains false or misleading information about voting;
                        </div>
                        <div class="ul">
                          Contains conspiracy-related content where the
                          underlying conspiracy theory makes claims that (1)
                          suggest that a real-world tragedy did not occur, or
                          (2) violate other content restrictions; or
                        </div>
                        <div class="ul">Violates any applicable law.</div>
                      </blockquote>
                      Please see the SIVOO Inc. Guidelines for guidance on how
                      we interpret these terms.
                    </p>
                  </li>
                  <li>
                    Code of Conduct
                    <p>
                      In using our Services, you may not:
                      <blockquote>
                        <div class="ul">
                          Use an offensive screen name (e.g., explicit language)
                          or avatar (e.g., containing nudity);
                        </div>
                        <div class="ul">
                          Act in a deceptive manner or impersonate any person or
                          organization;
                        </div>
                        <div class="ul">Harass or stalk any person;</div>
                        <div class="ul">Harm or exploit minors;</div>
                        <div class="ul">
                          Distribute “spam” in any form or use misleading
                          metadata;
                        </div>
                        <div class="ul">
                          Collect personal information about others;
                        </div>
                        <div class="ul">
                          Access another’s account without permission;
                        </div>
                        <div class="ul">
                          Use or export any of our services in violation of any
                          U.S. export control laws;
                        </div>
                        <div class="ul">Engage in any unlawful activity;</div>
                        <div class="ul">
                          Embed our video player on or provide links to sites
                          that contain content prohibited by <b>Section 5.2</b>;
                          or
                        </div>
                        <div class="ul">
                          Cause or encourage others to do any of the above.
                        </div>
                      </blockquote>
                    </p>
                  </li>
                  <li>
                    Prohibited Technical Measures
                    <p>
                      You will not:
                      <blockquote>
                        <div class="ul">
                          Except as authorized by law or as permitted by us:
                          scrape, reproduce, redistribute, create derivative
                          works from, decompile, reverse engineer, alter,
                          archive, or disassemble any part of our Services; or
                          attempt to circumvent any of our security,
                          rate-limiting, filtering, or digital rights management
                          measures;
                        </div>
                        <div class="ul">
                          Submit any malicious program, script, or code;
                        </div>
                        <div class="ul">
                          Submit an unreasonable number of requests to our
                          servers; or
                        </div>
                        <div class="ul">
                          Take any other actions to manipulate, interfere with,
                          or damage our Services.
                        </div>
                      </blockquote>
                    </p>
                  </li>
                  <li>
                    Restricted Users
                    <p>
                      You may not create or maintain an account if you are a
                      member of a terror or hate group. You may not purchase any
                      goods or software services from us if you are (a) located
                      in a country that is subject to a U.S. Government embargo
                      or has been designated by the U.S. Government as a
                      terrorist-supporting country; or (b) listed on any U.S.
                      Government list of restricted parties.
                    </p>
                  </li>
                  <li>
                    Accessibility and Ratings
                    <p>
                      We provide means to allow you to include closed captioning
                      in your videos. If required by applicable law, you must
                      provide closed captioning in your videos. We may allow you
                      to filter videos based upon their user-defined content
                      rating. We cannot guarantee that videos will be
                      appropriately rated by others. You must rate your videos
                      appropriately.
                    </p>
                  </li>
                </ul>
              </p>
            </li>
            <li>
              Licenses Granted by You
              <p>
                As between you and SIVOO Inc., you own and will retain ownership
                of all intellectual property rights in and to the content you
                submit. In order to allow SIVOO Inc. to host and stream your
                content, you grant SIVOO Inc. the permissions set forth below.
                <ul class="privacy">
                  <li>
                    Your Video Content
                    <p>
                      By submitting a video, you grant SIVOO permission to:
                      <blockquote>
                        <div class="ul">Stream the video to end users;</div>
                        <div class="ul">
                          Embed the video on third-party websites;
                        </div>
                        <div class="ul">Distribute the video via our APIs;</div>
                        <div class="ul">
                          Make the video available for download;
                        </div>
                        <div class="ul">
                          Transcode the video (create compressed versions of
                          your video file that are optimized for streaming);
                        </div>
                        <div class="ul">
                          Generate stills (i.e., “thumbnails”) from your video
                          to represent it (if you have not selected one);
                        </div>
                        <div class="ul">
                          Automatically generate and display closed captions or
                          subtitles from your video; and
                        </div>
                        <div class="ul">
                          Alter or enhance your video as directed by you.
                        </div>
                      </blockquote>
                    </p>
                    <p>
                      If you have enabled a video privacy setting or disabled
                      downloading or embedding, we will limit distribution of
                      your video pursuant to your selection. By enabling access
                      to your video to any third party, you grant each such
                      person permission to stream (and/or download or embed, as
                      applicable) your video. For the purposes of this{" "}
                      <b>Section 6.1</b>, your video includes its title,
                      description, tags, and other metadata.
                    </p>
                    <p>
                      The license period begins when you submit the video to
                      SIVOO Inc. and ends when you or SIVOO Inc. delete it;
                      provided that SIVOO Inc. may retain archival copies: (a)
                      for a limited period of time in case you wish to restore
                      it; (b) when the video is the subject of a takedown notice
                      or other legal claim; or (c) when SIVOO Inc. in good faith
                      believes that it is legally obligated to do so.
                    </p>
                  </li>
                  <li>
                    SIVOO Inc. Create Content
                    <p>
                      You may submit certain content to us for the purpose of
                      creating a video using SIVOO Inc. Create. These
                      submissions, and the resulting videos, are governed by our
                      SIVOO Inc. Create Addendum.
                    </p>
                  </li>
                  <li>
                    Account Profile
                    <p>
                      You grant SIVOO Inc. permission to use your name,
                      likeness, biography, trademarks, logos, or other
                      identifiers used by you in your account profile for the
                      purpose of displaying such properties to the public or the
                      audiences you have specified. You may revoke the foregoing
                      permission by deleting your account. SIVOO Inc. shall have
                      the right to identify public profiles in its marketing and
                      investor materials.
                    </p>
                  </li>
                  <li>
                    Other Content; Feedback
                    <p>
                      Content that is not covered by the licenses set forth in{" "}
                      <b>Sections 6.1</b>, <b>6.2</b>, or <b>6.3</b> shall be
                      governed by this <b>Section 6.4</b> (e.g., text you submit
                      in comments). You grant SIVOO Inc. a perpetual and
                      irrevocable right and license to copy, transmit,
                      distribute, publicly perform, and display such content
                      through online means in connection with our Services. If
                      you make suggestions to SIVOO Inc. on improving our
                      products or services, SIVOO Inc. may use your suggestions
                      without any compensation to you.
                    </p>
                  </li>
                  <li>
                    Scope of Licenses
                    <p>
                      All licenses granted by you in this <b>Section 6</b>: (a)
                      are non-exclusive, worldwide, and royalty-free; (b)
                      include the right and license to copy, use, distribute,
                      publicly perform, and display the licensed work for the
                      purposes stated above; and (c) include all necessary
                      rights and licenses to allow us to exercise our rights and
                      perform our obligations. By granting these licenses, you
                      waive any so-called “moral rights” that you may have.
                      Nothing in this Agreement shall be deemed a license
                      “condition” applicable to SIVOO Inc.; rather, any breach
                      of a term by SIVOO Inc. hereof shall give rise to, at
                      most, a claim for breach of contract only. All licenses
                      granted herein are in addition to any other licenses that
                      you may grant (e.g., a Creative Commons license).
                    </p>
                  </li>
                </ul>
              </p>
            </li>
            <li>
              Your Obligations
              <ul class="privacy">
                <li>
                  Representations and Warranties
                  <p>
                    For each piece of content that you submit to or through
                    SIVOO Inc., you represent and warrant that:
                    <blockquote>
                      <div class="ul">
                        You have the right to submit the content to SIVOO Inc.
                        and grant the licenses herein;
                      </div>
                      <div class="ul">
                        SIVOO Inc. TV will not need to obtain licenses from any
                        third party or pay royalties to any third party with
                        respect to the streaming or other permitted distribution
                        of the content;
                      </div>
                      <div class="ul">
                        You have obtained appropriate releases (if necessary)
                        from all persons who appear in the content;
                      </div>
                      <div class="ul">
                        The content does not, and will not, infringe any third
                        party's rights, including intellectual property rights,
                        rights of publicity, moral rights, and privacy rights;
                        and
                      </div>
                      <div class="ul">
                        The content complies with this Agreement and all
                        applicable laws.
                      </div>
                    </blockquote>
                  </p>
                </li>
                <li>
                  Indemnification
                  <p>
                    You will indemnify, defend, and hold harmless SIVOO Inc. and
                    its subsidiaries, parents, and affiliates, and theirs and
                    our respective directors, officers, employees, and agents,
                    from and against all third-party complaints, demands,
                    claims, damages, losses, costs, liabilities, and expenses,
                    including attorney’s fees, arising from or relating to: (a)
                    the content you submit to or through the Services; and (b)
                    allegations of actions or omissions by you that (regardless
                    if proven) would constitute a breach of this Agreement.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              Term and Termination
              <p>
                This Agreement begins when you first use our Services and
                continues so long as you use our Service or have an account with
                us, whichever is longer. Paid accounts will continue for the
                subscription period and will renew in accordance with{" "}
                <b>Section 4</b> above. With respect to users who do not have a
                subscription plan (i.e., free users), (a) SIVOO Inc. may
                terminate this Agreement at any time by providing thirty (30)
                days’ written notice, and (b) applicable users may terminate at
                any time by deleting their accounts.
              </p>
              <p>
                If you breach this Agreement, SIVOO Inc. may, at its option: (a)
                terminate this Agreement immediately, with or without advance
                written notice; (b) suspend, delete, or limit access to your
                account (and other accounts you control) or any content within
                it; and (c) to the extent permitted by applicable law, retain
                any amounts payable to you (which you forfeit). If SIVOO deletes
                your account for breach, you may not re-register.
              </p>
              <p>
                In the event of any termination or expiration, the following
                sections will survive: <b>Section 6.4</b> (Other Content;
                Feedback), <b>Section 7.2</b> (Indemnification),{" "}
                <b>Section 9</b> (Disclaimers), <b>Section 10</b> (Limitation of
                Liability), <b>Section 11</b> (Disputes, Arbitration, and Choice
                of Law), and <b>Section 12</b> (General Provisions).
              </p>
            </li>
            <li>
              Disclaimers
              <p>
                SIVOO INC. PROVIDES THE SERVICES ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. YOU USE THE SERVICES AT YOUR OWN RISK. You
                must provide your own device and internet access.
              </p>
              <p>
                EXCEPT AS EXPRESSLY SET FORTH HEREIN, AND TO THE EXTENT
                PERMITTED BY APPLICABLE LAW, SIVOO INC. DISCLAIMS ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. Among other things, SIVOO INC. makes no
                representations or warranties:
                <blockquote>
                  <div class="ul">
                    That our Services, or any part thereof, will be available or
                    permitted in your jurisdiction, uninterrupted or error-free,
                    completely secure, or accessible from all devices or
                    browsers;
                  </div>
                  <div class="ul">
                    That we will host, make available, or remove any specific
                    piece of content;
                  </div>
                  <div class="ul">
                    Concerning any content submitted by or actions of our users;
                  </div>
                  <div class="ul">
                    That any geo-filtering or digital rights management solution
                    that we might offer will be effective;
                  </div>
                  <div class="ul">
                    That we comply with the Health Insurance Portability and
                    Accountability Act (HIPAA), the Gramm-Leach-Bliley Act
                    (GLBA), or any other industry-specific privacy obligations;
                  </div>
                  <div class="ul">
                    That our Services will meet your business or professional
                    needs;
                  </div>
                  <div class="ul">
                    That we will continue to support any particular feature or
                    maintain backwards compatibility with any third-party
                    software or device; or
                  </div>
                  <div class="ul">
                    Concerning any third-party websites and resources.
                  </div>
                </blockquote>
              </p>
            </li>
            <li>
              Limitation of Liability
              <p>
                TO THE EXTENT PERMITTED BY APPLICABLE LAW: (A) SIVOO INC. SHALL
                NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES
                FOR LOSS OF BUSINESS, PROFITS, GOODWILL, DATA, OR OTHER
                INTANGIBLE LOSSES, EVEN IF SIVOO INC. HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES; AND (B) SIVOO INC.'S TOTAL
                LIABILITY TO YOU, EXCEPT FOR SIVOO INC.’S CONTRACTUAL PAYMENT
                OBLIGATIONS HEREUNDER (IF ANY), SHALL NOT EXCEED THE AMOUNTS
                PAID BY YOU TO SIVOO INC. OVER THE TWELVE (12) MONTHS PRECEDING
                YOUR CLAIM(S) OR ONE HUNDRED DOLLARS (USD $100), WHICHEVER IS
                GREATER.
              </p>
            </li>
            <li>
              Disputes, Arbitration, and Choice of Law
              <p>
                If you are dissatisfied with our Services for any reason, please
                contact us first so that we can try to resolve your concerns
                without the need for outside assistance.
                <ul class="privacy">
                  <li>
                    Choice of Law
                    <p>
                      Any disputes relating to this Agreement or your use of our
                      Services will be governed by the laws of Pennsylvania
                      State and the United States of America (with respect to
                      matters subject to federal jurisdiction such as
                      copyright), without regard to principles of conflicts of
                      law. The arbitration agreement set forth in{" "}
                      <b>Section 11.3</b> will be governed by the Federal
                      Arbitration Act.
                    </p>
                  </li>
                  <li>
                    Choice of Venue for Litigation; Jury Trial Waiver
                    <p>
                      Except for matters that must be arbitrated (as set forth
                      below), you and SIVOO Inc. agree that any action relating
                      to this Agreement or your use of our Services must be
                      commenced in either the Commercial Division of the New
                      York State Supreme Court for New York County or the United
                      States Federal Court for the Southern District of New
                      York; you consent to the exclusive jurisdiction of those
                      courts. IN ANY SUCH ACTION, SIVOO INC. AND YOU IRREVOCABLY
                      WAIVE ANY RIGHT TO A TRIAL BY JURY.
                    </p>
                  </li>
                  <li>
                    Arbitration of Privacy Claims; Class Action Waiver
                    <p>
                      The exclusive means of resolving any Covered Privacy Claim
                      (defined below) shall be BINDING ARBITRATION. The
                      arbitration will be administered by JAMS under the JAMS
                      Streamlined Arbitration Rules & Procedures, as modified by
                      our Arbitration Procedures. If you are a consumer, as
                      defined by JAMS in its Consumer Minimum Standards, you may
                      request that the arbitration hearing be conducted in the
                      area in which you reside. Otherwise, the hearing (if any)
                      shall take place in New York County, New York State,
                      United States of America. EACH PARTY WAIVES ITS RIGHT TO
                      GO TO COURT, TO A TRIAL BY JURY, AND TO PARTICIPATE IN A
                      CLASS ACTION, CLASS ARBITRATION, OR OTHER REPRESENTATIVE
                      PROCEEDING WITH RESPECT TO ANY COVERED PRIVACY CLAIM.
                    </p>
                    <p>
                      <b>Overview:</b> Arbitration provides a private dispute
                      resolution process that is usually more streamlined and
                      less formal than litigation. In an arbitration, your
                      rights will be determined by a neutral third party called
                      an arbitrator, and not a judge or jury. Both you and SIVOO
                      Inc. are entitled to fundamentally fair proceedings at
                      every stage of the arbitration, including the hearing. The
                      arbitrator will decide all issues relating to the dispute,
                      including the question of arbitrability, and can grant any
                      relief that a court could grant. Decisions by the
                      arbitrator are enforceable in court and may be overturned
                      by a court only for very limited reasons. For details on
                      the arbitration process, see our Arbitration Procedures.
                    </p>
                    <p>
                      <b>Definitions:</b> A <b>“Covered Privacy Claim”</b> means
                      any dispute or claim arising out of or relating to (a)
                      SIVOO Inc.’s collection, use, storage, analysis, or
                      transfer of your Personal Information; (b) an alleged
                      breach of our Privacy Policy; (c) an alleged data breach
                      or unauthorized disclosure of data or content; or (d) an
                      allegation that SIVOO Inc. failed to comply with any
                      privacy or data security right or obligation.{" "}
                      <b>“Personal Information”</b> means any information about
                      you, including your registration information (e.g., email
                      address), payment information, location information,
                      device information, biometric identifiers or information,
                      IP address, and your activities (including viewing and
                      search history), but does not include content that you
                      upload (except to the extent the content contains personal
                      information about you).
                    </p>
                    <p>
                      <b>Small Claims Court Exception:</b> Notwithstanding the
                      above, each party has the right to bring an individual
                      Covered Privacy Claim against the other in a small claims
                      court of competent jurisdiction pursuant to Rule 1 of
                      JAMS’ Minimum Consumer Standards. If one party files an
                      arbitration that could be litigated in such a small claims
                      court, the responding party may request that the dispute
                      proceed in small claims court. If the responding party
                      requests to proceed in small claims court before the
                      appointment of the arbitrator, the arbitration shall be
                      administratively closed. If requested after the
                      appointment of the arbitrator, the arbitrator shall
                      administratively close the arbitration so long as the
                      proceedings are at an early stage and no hearing has
                      occurred.
                    </p>
                  </li>
                </ul>
              </p>
            </li>
            <li>
              General Provisions
              <p>
                <b>Reservation of Rights, Severability:</b> SIVOO Inc. reserves
                all rights not expressly granted herein. SIVOO Inc.’s rights and
                remedies are cumulative. No failure or delay by SIVOO Inc. in
                exercising any right will waive any further exercise of that
                right. If any term of this Agreement is found invalid or
                unenforceable by a court of competent jurisdiction, that term
                will be limited or severed.
              </p>
              <p>
                <b>Force Majeure:</b> SIVOO Inc. will not be liable for any
                delay or failure caused by (a) acts of God/natural disasters
                (including hurricanes and earthquakes); (b) disease, epidemic,
                or pandemic; (c) terrorist attack, civil war, civil commotion or
                riots, armed conflict, sanctions or embargoes; (d) nuclear,
                chemical, or biological contamination; (e) collapse of
                buildings, fire, explosion, or accident; (f) labor or trade
                strikes; (g) interruption, loss, or malfunction of a utility,
                transportation, or telecommunications service; (h) any order by
                a government or public authority, including a quarantine, travel
                restriction, or other prohibition; or (i) any other circumstance
                not within SIVOO Inc.’s reasonable control, whether or not
                foreseeable (each a <b>“force majeure event”</b>). In the event
                of a force majeure event, SIVOO Inc. shall be relieved from full
                performance of the contractual obligation until the event passes
                or no longer prevents performance.
              </p>
              <p>
                <b>Relationship:</b> You and SIVOO Inc. are independent
                contractors of one another; neither party is an agent, partner,
                or joint venture of the other. This Agreement binds the parties
                and their successors, personal representatives, and permitted
                assigns. You may not assign this Agreement to any person whose
                account has been terminated by SIVOO Inc. or who is prohibited
                from registering; any such assignment will be void. Except as
                expressly stated herein, nothing in this Agreement confers any
                right on any third party.
              </p>
              <p>
                <b>Entire Agreement:</b> This Agreement constitutes the entire
                understanding of the parties and supersedes all prior
                understandings regarding the subject matter hereof and may not
                be modified except in accordance with <b>Section 1</b> or in a
                document executed by authorized representatives of SIVOO Inc. If
                you have a signed agreement with SIVOO Inc., any conflicting
                term of that agreement will prevail over the terms hereof, but
                only as to the subject matter of that agreement.
              </p>
              <p>
                The English version of this Agreement shall control. For
                convenience, we may provide translated versions of this
                Agreement.
              </p>
              <h2>Contact Us:</h2>
              <p>
                <b>Notices:</b> You must send any notices of a legal nature to
                us by email or at:
                <blockquote>
                  <em>
                    <b>SIVOO Inc.</b>
                    <br />
                    Attention: Legal Department
                    <br />
                    1900 JFK Blvd., Suite 1323
                    <br />
                    Philadelphia, PA 19103, USA
                  </em>
                </blockquote>
              </p>
              <p>
                Grievance Officer:
                <br />
                <blockquote>
                  <em>
                    Sean Berner
                    <br />
                    +1 844-467-4866
                    <br />
                    grievances@sivoo.com
                    <br />
                    <br />
                    Sivoo Inc.
                    <br />
                    Attention: Grievances
                    <br />
                    1900 JFK Blvd., Suite 1323
                    <br />
                    Philadelphia, PA 19103, USA
                  </em>
                </blockquote>
              </p>
            </li>
          </ol>
        </Box>
      </Container>
    </div>
  );
};

export default Terms;
