import { Box, Container, Stack, Typography } from "@mui/material";
import "../../../styles/Privacy-Terms.css";

const Privacy = () => {
  // on first load scroll to top
  window.scrollTo(0, 0);

  return (
    <div>
      {" "}
      <Container sx={{ pt: 8, pb: 4 }}>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography sx={{ my: 2 }} variant="h1">
              Privacy Policy
            </Typography>
            <Typography variant="body1">
              Last updated: February 9, 2023
            </Typography>
          </Box>
          <a
            href="https://www.sivoo.com/assets/pdf/Sivoo_PrivacyPolicy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={"./assets/icons/pdfIcon.png"} alt="" />
          </a>
        </Stack>
      </Container>
      {/* terms  */}
      <Container sx={{ py: 6 }}>
        <Typography
          variant="body1"
          sx={{ mb: 2, fontSize: { xs: 18, md: 22, fontWeight: "300" } }}
        >
          This <b>SIVOO Inc.</b> <b>Terms of Service Agreement</b> (the
          <b>“Agreement”</b> or <b>“Terms of Service”</b> ) is made between
          SIVOO, Inc. (<b>“SIVOO Inc.,”</b> <b>“we,” “us,” or “our”</b> ) and
          you, our customer (<b>“you”</b> or <b>“your”</b> ). This Agreement
          governs your use of SIVOO Inc. owned-and-operated websites,
          applications, and embeddable video players (collectively, the
          <b> “Services”</b> ). This includes our SIVOO, SIVOOTV, SIVOO OTT, and
          Livestream services.
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 2, fontSize: { xs: 18, md: 22, fontWeight: "300" } }}
        >
          The following apps belong to Sivoo Inc.:
          <br />
          HERITAGE TOURISM, HERITAGE+, S+, ESCAPE TV, BANG BANG TV, HEARTFELT
          TV, LOL TV, OUTER VISION TV, HORROR TV, THE CHUKKER CHANNEL TV, DANGER
          VISION TV, ENCORE+, KIDDO+, MERCURY+, OSC+.
        </Typography>
        <Box sx={{ px: 4 }}>
          <ol className="privacy">
            <li>
              WHAT INFORMATION SIVOO COLLECTS FROM YOU
              <p>
                SIVOO may collect two (2) types of information about you:
                Personal and Non-Personal.
              </p>
              <ul className="privacy">
                <li>
                  Personal Information
                  <p>
                    Personal Information refers to information that lets SIVOO
                    know the specifics of who you are and which may be used to
                    identify, contact, or locate you (e.g. name, mailing
                    address, telephone number and email address). SIVOO may
                    collect Personal Information when you use the SIVOO
                    Properties including, without limitation, setting up account
                    information, filling out surveys, corresponding with SIVOO,
                    or otherwise volunteering information about yourself.
                  </p>
                </li>
                <li>
                  Non-Personal Information
                  <p>
                    Non-Personal Information refers to information that, by
                    itself, does not identify you as a specific individual (e.g.
                    demographic information or website visitations). SIVOO may
                    collect Non-Personal Information through any of the methods
                    discussed above as well as automatically through the use of
                    industry-standard technologies described further below.
                  </p>
                </li>
                <li>
                  Types of Data Collected
                  <p>
                    We collect all types of data from our visitors, including
                    hashed emails, etc. in order to track visitors’
                    cross-device, via email, by partners like Reliance Jio and
                    KaiOS.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              HOW SIVOO COLLECTS YOUR INFORMATION
              <p>
                The Personal Information practices set forth apply to all
                SIVOO’s customers worldwide.
              </p>
              <ul className="privacy">
                <li>
                  Registration
                  <p>
                    Prior to using one or more of the SIVOO Properties, SIVOO
                    may require certain Personal Information and Non-Personal
                    Information to create an individual account (“Account”) or
                    to enable features or functionality of the SIVOO Properties.
                    Failure to provide all required information required by
                    SIVOO may prevent access to any or all SIVOO Properties, and
                    failure to maintain accurate information may result in
                    suspension or termination of access to any or all SIVOO
                    Properties.
                  </p>
                </li>
                <li>
                  Information Collected Through Technology
                  <p>
                    While you are able to visit the SIVOO Site and remain
                    anonymous, SIVOO or its third-party service providers may
                    still collect Non-Personal Information about your use of the
                    SIVOO Properties (e.g. your Internet browser, operating
                    system, IP address, connection speed, and the domain name of
                    your Internet service provider). Such information may be
                    gathered by the following methods:
                  </p>
                  <div className="alpha">
                    <p>
                      <b>Cookies.</b> Cookies, including local shared objects,
                      are small pieces of information that are stored by your
                      browser on your computer’s hard drive which works by
                      assigning to your computer a unique number that has no
                      meaning outside of the SIVOO Site. Cookies do not
                      generally contain any Personal Information. Most web
                      browsers automatically accept cookies, but you can usually
                      configure your browser to prevent this. Not accepting
                      cookies may make certain features of the SIVOO Properties
                      unavailable to you.
                    </p>
                  </div>
                  <div className="alpha">
                    <p>
                      <b>IP Address.</b> You may visit many areas of the SIVOO
                      Site anonymously without the need to become a registered
                      User. Even in such cases, SIVOO may collect IP addresses
                      automatically. An IP address is a number that is
                      automatically assigned to your computer whenever you begin
                      Services with an Internet services provider. Each time you
                      access the SIVOO Site and each time you request one of
                      SIVOO’s pages, the server logs your IP address.
                    </p>
                  </div>
                  <div className="alpha">
                    <p>
                      <b>Tracking Content Usage.</b> If you use the SIVOO
                      Services and you post audiovisual materials including,
                      without limitation, text, logos, artwork, graphics,
                      pictures, advertisements, sound and other related
                      intellectual property contained in such materials
                      (collectively, “Content”) to your website or to a
                      third-party website, SIVOO tracks and captures
                      Non-Personal Information associated with User accounts and
                      the use of Content.
                    </p>
                  </div>
                </li>
              </ul>
            </li>
            <li>
              HOW SIVOO USES YOUR INFORMATION
              <p></p>
              <ul className="privacy">
                <li>
                  System Administration
                  <p>
                    SIVOO may use Non-Personal Information for the purposes of
                    system administration, assisting in diagnosing problems with
                    SIVOO servers, monitoring SIVOO’s system performance and
                    traffic on the SIVOO Properties and to gather broad
                    demographic information about SIVOO customers.
                  </p>
                </li>
                <li>
                  Personalization
                  <p>
                    SIVOO uses Cookies and IP Addresses to track features such
                    as delivering Content specific to your interests and
                    informing you of new, relevant services or certain
                    third-party offerings.
                  </p>
                </li>
                <li>
                  Notices to Registered Users
                  <p>
                    If you have registered for an Account, SIVOO may use your
                    Personal Information to send emails regarding your
                    registration, including confirmation to verify the accuracy
                    of any information you have provided, and instructions on
                    how to post Content. SIVOO may also send you emails to
                    verify your identity or to notify you if SIVOO believes your
                    use of SIVOO Properties violates any applicable agreement
                    for the use of the SIVOO Properties.
                  </p>
                </li>
                <li>
                  Advertising
                  <p>
                    SIVOO may use Non-Personal Information to assist in
                    optimizing the delivery of advertisements to viewers of your
                    Content.
                  </p>
                </li>
                <li>
                  Promotional Emails
                  <p>
                    SIVOO may use your Personal Information to send you emails
                    periodically listing promotions or events relating to the
                    SIVOO Properties or from SIVOO’s marketing partners or
                    sponsors. You have the choice to opt-out of receiving such
                    promotional emails by sending an email to support@sivoo.com
                    and/or following the instructions in such correspondence.
                    Once SIVOO has processed your opt-out request, SIVOO will
                    not send you promotional emails unless you opt back into
                    receiving such communications.
                  </p>
                </li>
                <li>
                  Contact Information
                  <p>
                    If you contact SIVOO by telephone, email, or letter, SIVOO
                    may keep a record of your contact information and
                    correspondence. If you report a problem with the SIVOO
                    Properties, SIVOO may collect this information in a file
                    specific to you. You may contact SIVOO at help@sivoo.com to
                    request the removal of this information from SIVOO’s
                    database.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              HOW TO ACCESS AND CHANGE YOUR PERSONAL INFORMATION
              <p>
                Upon request, SIVOO shall allow Users to update or correct
                Personal Information previously submitted, but only to the
                extent, such activities will not compromise privacy or security
                interests. Additionally, upon request, SIVOO shall delete
                Personal Information from the database where such information is
                stored; however, it may be impossible to entirely delete a
                User’s entry without some residual information being retained
                due to the manner in which data backups are maintained. Requests
                to delete Personal Information shall be submitted to
                support@sivoo.com.
              </p>
            </li>
            <li>
              DISCLOSURE OF INFORMATION TO THIRD PARTIES
              <p>
                SIVOO does not sell, trade or rent Personal Information
                collected through the SIVOO Properties to any third party.
                Notwithstanding the foregoing, SIVOO shall: (1) fully cooperate
                with law enforcement agencies; and (2) may be required to
                disclose Personal Information upon receipt of a court order,
                subpoena, or to cooperate with a law enforcement investigation.
              </p>
              <p>
                Here are the third-party data processors, storage, and
                infrastructure partners:
                <blockquote>
                  <div className="ol">
                    Billing services to enable payment for the services and the
                    provisioning of the services
                  </div>
                  <div className="ol">
                    Operational data that enable us to actually provide the
                    streaming services
                  </div>
                  <div className="ol">
                    Paywall services to provide services allowing our customers
                    to monetize their content through Pay-per-View and
                    Subscriptions
                  </div>
                  <div className="ol">
                    Communication and customer service tools to help assist
                    customers and communicate with them
                  </div>
                  <div className="ol">
                    Data tools in order to analyze and report within the company
                    to improve the service
                  </div>
                </blockquote>
              </p>
            </li>
            <li>
              SAFEGUARDING YOUR PERSONAL INFORMATION
              <ul className="privacy">
                <li>
                  Security Measures
                  <p>
                    SIVOO takes appropriate security measures to protect against
                    unauthorized access, alteration, disclosure, or destruction
                    of Personal Information. These include, but are not limited
                    to, internal reviews of: (a) SIVOO’s data collection; (b)
                    storage and processing practices; (c) electronic security
                    measures, and (d) physical security measures to guard
                    against unauthorized access to systems where SIVOO stores
                    Personal Information. All SIVOO employees, contractors, and
                    agents who access Personal Information are bound by
                    confidentiality obligations and may be subject to
                    discipline, including termination and criminal prosecution
                    or unauthorized use or disclosure of Personal Information.
                  </p>
                </li>
                <li>
                  Third-Party Links
                  <p>
                    The SIVOO Properties may contain links to third parties who
                    may collect Personal Information and Non-Personal
                    Information directly from you. Additionally, SIVOO may use
                    third parties to provide components of the SIVOO Properties.
                    In either case, such third parties may have separate privacy
                    policies and data collection practices, independent of
                    SIVOO. SIVOO: (a) has no responsibility or liability for
                    these independent policies or actions; (b) is not
                    responsible for the privacy practices or the content of such
                    websites; and (c) does not make any warranties or
                    representations about the contents, products or services
                    offered on such websites or the security of any information
                    you provide to them.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              WHAT ARE YOUR DATA PROTECTION RIGHTS?
              <p>
                SIVOO would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p>
                <b>The right to access</b>&mdash;You have the right to request
                SIVOO for copies of your personal data. We may charge you a
                small fee for this service.
              </p>
              <p>
                <b>The right to rectification</b>&mdash;You have the right to
                request that SIVOO correct any information you believe is
                inaccurate. You also have the right to request SIVOO to complete
                the information you believe is incomplete.
              </p>
              <p>
                <b>The right to erasure</b>&mdash;You have the right to request
                that SIVOO erase your personal data, under certain conditions.
              </p>
              <p>
                <b>The right to restrict processing</b>&mdash;You have the right
                to request that SIVOO restrict the processing of your personal
                data, under certain conditions.
              </p>
              <p>
                <b>The right to object to processing</b>&mdash;You have the
                right to object to SIVOO’s processing of your personal data,
                under certain conditions.
              </p>
              <p>
                <b>The right to data portability</b>&mdash;You have the right to
                request that SIVOO transfer the data that we have collected to
                another organization, or directly to you, under certain
                conditions.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us at our email: <b>privacy@sivoo.com</b>
              </p>
            </li>
            <li>
              COOKIES
              <ul className="privacy">
                <li>
                  Definition
                  <p>
                    Cookies are text files placed on your computer to collect
                    standard Internet log information and visitor behavior
                    information. When you visit our websites, we may collect
                    information from you automatically through cookies or
                    similar technology
                  </p>
                  <p>For further information, visit allaboutcookies.org.</p>
                </li>
                <li>
                  How do we use cookies?
                  <p>
                    SIVOO uses cookies in a range of ways to improve your
                    experience on our website, including:
                    <blockquote>
                      <div className="ul">Keeping you signed in</div>
                      <div className="ul">
                        Understanding how you use our website
                      </div>
                      <div className="ul">
                        Track features such as delivering content specific to
                        your interests
                      </div>
                      <div className="ul">
                        Informing you of new, relevant services
                      </div>
                    </blockquote>
                  </p>
                </li>
                <li>
                  What types of cookies do we use?
                  <p>
                    There are a number of different types of cookies, however,
                    our website uses:
                    <blockquote>
                      <div className="ul">
                        Functionality – SIVOO uses these cookies so that we
                        recognize you on our website and remember your
                        previously selected preferences. These could include
                        what language you prefer and the location you are in. A
                        mix of first-party and third-party cookies are used.
                      </div>
                      <div className="ul">
                        Advertising – SIVOO uses these cookies to collect
                        information about your visit to our website, the content
                        you viewed, the links you followed, and information
                        about your browser, device, and your IP address. SIVOO
                        sometimes shares some limited aspects of this data with
                        third parties for advertising purposes. We may also
                        share online data collected through cookies with our
                        advertising partners. This means that when you visit
                        another website, you may be shown advertising based on
                        your browsing patterns on our website.
                      </div>
                    </blockquote>
                  </p>
                </li>
                <li>
                  How to manage cookies?
                  <p>
                    You can set your browser not to accept cookies, and the
                    above website tells you how to remove cookies from your
                    browser. However, in a few cases, some of our website
                    features may not function as a result.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              PRIVACY POLICIES OF OTHER WEBSITES
              <p>
                The SIVOO website contains links to other websites. Our privacy
                policy applies only to our website, so if you click on a link to
                another website, you should read their privacy policy.
              </p>
            </li>
            <li>
              CHANGES TO SIVOO’S PRIVACY POLICY
              <p>
                SIVOO continually strives to implement new technologies and
                processes to better protect you, your privacy, and your use of
                the SIVOO Properties. As a result, changes to this Privacy
                Policy may be made by SIVOO from time to time. In no event will
                these changes result in the degradation of any of the security
                measures designed to protect you from unauthorized disclosure,
                as such measures are described in this Privacy Policy.
              </p>
            </li>
            <li>
              SPECIAL NOTE REGARDING CHILDREN UNDER 13 YEARS OF AGE
              <p>
                The use of the SIVOO Properties is not intended for minors under
                the age of 18 (“Minors”). Minors are not authorized by SIVOO to
                use the SIVOO Properties, even if Minors set up an Account or
                accept the terms of any agreement for SIVOO Properties. Parents
                or guardians may authorize Minors between the ages of 13 and 17
                to use the SIVOO Properties, provided they assume all
                responsibility and legal liability for the conduct of such Minor
                including, without limitation, monitoring the Minor’s access and
                use of the SIVOO Properties. SIVOO does not intentionally
                collect nor maintain any personally identifiable information
                from Minors. If you discover your Minor child has submitted
                his/her information to SIVOO, you may request to have such
                information deleted from SIVOO’s database by sending an email
                request to support@sivoo.com together with the email address
                that was submitted by the Minor. Upon receiving the request,
                SIVOO shall delete such information within twenty (20) business
                days. If SIVOO learns that a Minor is using the SIVOO
                Properties, SIVOO will require verified parental or guardian
                consent, in accordance with the Children’s Online Privacy
                Protection Act of 1998 (“COPPA”).
              </p>
            </li>
            <li>
              CONTACT SIVOO
              <p>
                If you have any questions about this Privacy Policy, please
                contact SIVOO by sending a letter or email to:
                <blockquote>
                  <em>
                    <b>SIVOO, Inc.</b>
                    <br />
                    Attention: Privacy
                    <br />
                    1900 JFK Blvd., Suite 1323
                    <br />
                    Philadelphia, PA 19103, USA
                    <br />
                    privacy@sivoo.com
                  </em>
                </blockquote>
              </p>
              <p>
                Grievance Officer:
                <br />
                <blockquote>
                  <em>
                    Name: Sean Berner
                    <br />
                    Phone: +1 844-467-4866
                    <br />
                    Email: grievances@sivoo.com
                    <br />
                    Attention: Grievances
                    <br />
                    1900 JFK Blvd., Suite 1323
                    <br />
                    Philadelphia, PA 19103, USA
                  </em>
                </blockquote>
              </p>
            </li>
          </ol>
        </Box>
      </Container>
    </div>
  );
};

export default Privacy;
