import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FAQs from "./FAQs";

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [value, setValue] = useState("sivootvFaq");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  // load faqs from ./faq.json
  useEffect(() => {
    fetch(`./json/shared/faq/${value}.json`)
      .then((res) => res.json())
      .then((data) => setFaqs(data));
  }, [value]);

  return (
    <div id="FAQs">
      <Box sx={{ py: 6 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, md: 20, lg: 6 }}
            columns={{ xs: 2, sm: 4, md: 6, lg: 12 }}
            flexDirection={{ xs: "column-reverse", lg: "row" }}
            sx={{ p: 0 }}
          >
            <Grid item xs={2} sm={4} md={6} lg={6}>
              <Box sx={{ width: 1, px: { xs: 3, md: 8 } }}>
                <Typography className="sectionTitle" variant="h3" gutterBottom>
                  FAQs
                </Typography>
                <Box sx={{ my: 6, width: 1 }}>
                  <TabContext value={value}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        color: "secondary",
                      }}
                    >
                      <TabList
                        textColor="secondary"
                        indicatorColor="secondary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={handleChange}
                      >
                        <Tab
                          sx={{
                            fontSize: { xs: 14, md: 18 },
                            lineHeight: "22px",
                          }}
                          value="sivootvFaq"
                          label="SivooTV"
                        />
                        <Tab
                          sx={{
                            fontSize: { xs: 14, md: 18 },
                            lineHeight: "22px",
                          }}
                          value="sivootvPlusFaq"
                          label="SivooTV+"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="sivootvFaq">
                      <FAQs faqs={faqs} />
                    </TabPanel>
                    <TabPanel value="sivootvPlusFaq">
                      <FAQs faqs={faqs} />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={4} md={6} lg={6}>
              <Box
                sx={{
                  // overflow: "hidden",e
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src="./assets/images/ottRoom.jpg"
                  alt="faq"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default FAQ;
