import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const DigitalMediaKit = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    fetch(`./json/press/digitalMediaKit.json`)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <div id={data.anchor}>
      <Box
        sx={{
          py: 6,
          px: { xs: 3, md: 8 },
        }}
      >
        <Typography className="sectionTitle" variant="h3">
          {data.sectionTitle}
        </Typography>

        <Typography sx={{ my: 6, whiteSpace: "pre-line" }} variant="body1">
          {data.description}
        </Typography>
        {/* <Button variant="text" color="secondary">
          <a
            style={{
              color: "#ff6600",
            }}
            href={data.kitLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Digital Media Kit
          </a>
        </Button> */}
      </Box>
    </div>
  );
};

export default DigitalMediaKit;
